.form-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 10px;

    background-color: var(--card-background);

    border-radius: 3px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);

    margin-bottom: 10px;
}

.form-container label {
    font-family: var(--primary-font);
    color: var(--primary);
    padding: 5px;
    font-weight: bold;
}

.form-container input {
    padding: 10px;
    outline: none;
    border: 1px solid var(--primary);
    margin: 5px;
    border-radius: 5px;
    font-family: var(--form-font);
    font-weight: 500;
    letter-spacing: 2px;
}

.form-container button {
    padding: 10px;
    margin: 5px;
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
}

.form-container button:hover {
    background-color: #dadada;
}