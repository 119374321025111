.nav-container {
    display: flex;
    justify-content: space-evenly;
    padding: 5px;
    margin-top: 5%;
    margin-bottom: 5%;
    z-index: 100;
}

.nav-container a {
    text-decoration: none;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--primary);
    font-family: var(--primary-font);
}

.nav-container a:hover {
    border-bottom: 2px solid var(--primary);
}

.nav-container a.active {
    border-bottom: 2px solid var(--primary) ;
}

.nav-icon {
    display: none;
}

.nav-container h2 {
    display: none;
}



@media only screen and (max-width: 770px) {
    .nav-container {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #ffffff;
        margin: 0;
        width: 100%;
        height: 50px;

        flex-direction: column;
        justify-content:flex-start;
        align-items: center;

        transition: all ease 0.7s;
    }

    .nav-closed {
        opacity: 0;
        transform: scaleY(0);
        transition: all ease 0.7s;
    }

    .nav-open {
        height: 100%;
        opacity: 1;
        transition: all ease 0.7s;
    }

    .nav-icon {
        color: var(--secondary);
        display:block;
        position: absolute;
        font-size: 2.5rem;
        left: 10px;
        top: 10px;
    }

    .nav-container h2{
        display: block;
        margin: 0;
        font-family: var(--primary-font);
        color: var(--secondary);
        font-size: 2rem;
        font-weight: 500;
        position: absolute;
        padding: 5px;
        top: 10;
    }

    .nav-container a {
        color: var(--secondary);
        font-size: 1.2rem;
        padding: 10px;
    }

    .nav-container a.home-link {
        margin-top: 75px;
    }
}