@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

body{

  --primary: #ffffff; /*#1F584F; */
  --secondary: #2d2d2d;
  --background: #FF9909;
  --card-background: #FF9909;
  --primary-font:'Alegreya Sans', sans-serif;
  --secondary-font: 'Dancing Script', cursive;
  --event-title: 'Cookie', cursive;
  --event-text:'Alegreya Sans', sans-serif;
  --form-font: 'Noto Sans JP', sans-serif;

  --dancing-font: 'Dancing Script', cursive;
  --alegreya-font: 'Alegreya Sans', sans-serif;

  --cookie-font: 'Cookie', cursive;
  --tangerine-font: 'Tangerine', cursive;
}


html,
body, #root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--background) ;
    /* font-size: calc(0.4rem + 1vw); */
}

.App {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}


.main-wrapper {
  margin-top: -6%;
  width: 75%;
  max-width: 1200px;
  align-self: center;
  flex: 1;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}

.title h1 {
  margin: 0;
  padding: 2%;
  font-family: var(--dancing-font);
  font-size: 3rem;
  letter-spacing: 0.4rem;
  font-weight: 500;
}

.title p {
  margin: 0;
  font-size: 1.7rem;
  font-style: italic;
  font-family: var(--alegreya-font);
}

.headImg img {
  width: 100%;
  min-height: 150px;
  object-fit: contain;
}

.footImg img {
  width: 100%;
  min-height: 150px;
  object-fit: contain;
}

p.error {
  padding: 5px;
  margin: 0;
  color: red;
}

picture.footImg {
  margin-bottom: -10px;
}

@media only screen and (max-width: 770px) {
  .main-wrapper {
    width: 100%;
    padding: 5px;
    margin: 0;
  }

  .title h1 {
    font-size: 2.5rem;
    letter-spacing: 0;
    text-align: center;
  }

  .title p {
    text-align: center;
    font-size: 0.8rem;
  }

  .headImg {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  

  
}