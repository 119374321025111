.home-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.home-greeting {
    display: flex;
    flex-direction: column;
    font-family: var(--tangerine-font);
    color: var(--primary);
    letter-spacing: 0.1rem;
   
}

.home-left {
    flex: 1;
    text-align: center;
}

.home-right {
    flex: 1;
    text-align: center;
    border-top: 1px solid var(--primary);
}

.home-greeting h2 {
    padding: 2%;
    font-size: 2.5rem;
    font-weight: 100;
}

.home-container button {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: 10%;
    min-width: 100px;
    align-self: center;
    background-color: var(--primary);
    color: var(--secondary);
    font-family: var(--primary-font);
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    border: none;
    border-radius: 3px;
    margin-bottom: 3%;
    font-weight: bold;
}

.home-container button:hover, .home-container button:active {
    background-color: #dadada;
}

@media only screen and (max-width: 770px) {
    .home-greeting {
        margin: 0px;
        flex-direction: column;
        align-items: center;
    }

    .home-greeting h2 {
        width: 100%;
        font-size: 1.7rem;
    }

    .home-right {
        border-left: none;
        border-top: 1px solid var(--primary);
    }
}