.invite-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 200;
    background-color: rgba(0,0,0,0.6);

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--form-font);

    transition: all ease 0.4s;
}

.hidden {
    /* display: none; */
    opacity: 0;
    z-index: -100;
    transition: all ease 0.4s;
}

.invite-form {
    display: flex;
    flex-direction: column;
    padding: 5%;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    width: 70%;
    max-width: 500px;
    background-color: var(--card-background);

    position: relative;
}

.invite-form label {
    padding: 5px;
    font-weight: bold;
}

.invite-form input {
    font-family: var(--form-font);
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--primary);
}

.invite-form button {
    padding: 10px;
    margin: 5px;
    font-family: var(--form-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    width: 100%;
    align-self: center;
}

.invite-form button:hover {
    background-color: #dadada;
}

.invite-form p {
    margin: 3px;
    color: rgba(228, 8, 8, 0.959);
}

p.success {
    color: rgb(17 156 0);
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    color: var(--primary);
    cursor: pointer;
}