.footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgb(192,0,0);
}

.footer-container h2 {
  margin-bottom: 5%;
  padding: 2%;
  font-family: var(--tangerine-font);
  font-size: 3.0rem;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 2px solid #ffffff; ;
}

.footer-container p {
    font-family: var(--primary-font);
    font-size: 1.4rem;
    font-style: italic;
    margin: 5px;
}

.footer-nav {
  display: none;
}

a.footer-admin{
  font-family: var(--secondary-font);
  font-style: normal;
  font-size: small;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}

@media only screen and (max-width: 770px) {
  .footer-nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .footer-nav span {
    display: flex;
    padding: 6px;
    border-top: 1px solid #ffffff;
    border-left: none;
    border-right: none;
    justify-content: center;
  }

  .footer-container .footer-nav {
    border-bottom: 1px solid #ffffff ;
  }

  .footer-nav a {
    font-family: var(--primary-font);
    font-size: 1.2rem;
    padding: 2px;
    color: var(--primary);
    text-decoration: none;
    border-bottom: 2px solid rgba(0,0,0,0);
  }

  .footer-nav a.active {
    border-bottom: 2px solid var(--primary);
  }


}