.selector-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 10%;
    background-color: var(--card-background);
    border-radius: 3px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.selector-container h3 {
    font-family: var(--secondary-font);
    color: var(--primary);
}

.selectable {
    width: 60%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
}

.selectable:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.selectable p {
    margin: 0;
    color: var(--primary)
}

.selector-container .head {
    font-family: var(--primary-font);
    font-size: 1.4rem;
    font-weight: bold;
}

.selector-container .tail {
    font-family: var(--primary-font);
    font-size: 1rem;
    letter-spacing: 0.1rem;
}

.selectable .arrow {
    position: absolute;
    top: 45%;
    bottom: 0;
    right: 10px;
}

.select-retry {
    color: var(--primary);
}

.select-retry span {
    text-decoration: underline;
    cursor: pointer;
}

.selector-container button {
    padding: 10px;
    margin: 5px;
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    width: 100%;
    align-self: center;
}

.selector-container button:hover {
    background-color: #dadada;
}

@media only screen and (max-width: 770px) {
    .selector-container {
        padding: 10px;
    }

    .selectable {
        width: auto;
    }
}