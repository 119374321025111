.travel-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.travel-header {
    color: var(--primary);
    font-family: var(--alegreya-font);
    font-style: italic;
    font-size: 1.5rem;
}

.travel-title {
    color: var(--primary);
    font-family: var(--primary-font);
    margin: 0;
    font-weight: bold;
}

.travel-subtitle {
    color: var(--primary);
    font-family: var(--primary-font);
    margin-top: 0;
}

.travel-container a {
    text-decoration: none;
    color: inherit;
}

@media only screen and (max-width: 770px)  {
    .travel-container {
        display: flex;
        padding: 2%;
        margin: 2%;
    }
}