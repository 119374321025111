.container {
    display: flex;
    flex-direction: column;
    width: 800px;
    max-width: 100%;
    height: 600px;
    min-height: 600px;
    background-color: rgb(31, 31, 31);
    padding: 1%;
    position: relative;
    box-sizing: border-box;
    user-select: none;
}

.main {
    display: flex;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.068);
    align-items: center;
    justify-content: center;
    user-select: none;
}

.main img {
    width: auto;
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
    display: block;
}

.picker {
    display: flex;
    width: 100%;
    min-height: 85px;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
}

.preview {
    display: flex;
    width: 75px;
    height: 75px;
    background-color: rgb(14, 14, 14);
    border-radius: 8px;
    margin: 3px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(14, 14, 14);
    cursor: pointer;
    user-select: none;
}

.selected {
    border-color: green;
}

.preview:hover {
    border-color: white;
}

.preview img {
    max-width: 100%;
    object-fit: contain;
}

.next {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.452);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(-20px);
    z-index: 1;
}

.previous {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.452);
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(20px);
    z-index: 1;
}

.next:hover,
.previous:hover {
    background-color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 770px) {
    .container {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}