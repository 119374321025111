.admin-login {
    display: flex;
    flex-direction: column;
    background-color: var(--card-background);
    padding: 10%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    font-family: var(--form-font);
}

.admin-login h1 {
    color: var(--primary)
}

.admin-login label {
    color: var(--primary);
    font-weight: bold;
    padding: 5px;
}

.admin-login input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 2px solid #1f584f77;
    font-family: var(--form-font);
}

.admin-login input:focus {
    border: 2px solid var(--primary);
}

.admin-login button {
    padding: 10px;
    margin: 5px;
    font-family: var(--form-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    width: 100%;
    align-self: center;
}

.admin-login button:hover {
    background-color: #dadada;
}

.admin-login p {
    color: rgb(196, 9, 9);
}

.table-wrapper {
    
    max-width: 100%;
    overflow-x: scroll;
}

table.admin-table{
    box-sizing: border-box;
    border-collapse: collapse;
    font-family: var(--form-font);
    margin-left: auto;
    margin-right: auto;
}

table.admin-table tbody tr td {
   max-width: 100%;
}



.admin-container table td, 
.admin-container table th {
    padding: 12px 15px;
    border: 1px solid #dddddd;
    text-align: center;
    font-size: 0.9rem;
}

.admin-container table th {
    background-color: var(--primary);
    color: var(--card-background);
    font-size: 0.7rem;
    position: relative;
}

.admin-container tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

.admin-container table input {
    box-sizing: border-box;
    width: 100%;
    font-family: var(--form-font);
    height: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}

.admin-container table textarea {
    box-sizing: border-box;
    width: 100%;
    font-family: var(--form-font);
    height: 100%;
    padding: 5px;
    border: none;
    resize: none; 
    outline: none;
    background-color: rgba(0, 0, 0, 0);
}

td.input-box {
    padding: 0px !important;
}

.admin-container table span {
    border-radius: 2px;
    cursor: pointer;
    background-color: var(--primary);
    padding: 2px;
    color:var(--secondary);
    margin-top: 15%;
}

.admin-container table span:hover {
    background-color: #dadada;
}

td.action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 !important;
}

.invite-button {
    padding: 12px 20px;
    margin: 3px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    font-family: var(--form-font);
}

.admin-button-control {
    display: flex;
    justify-content: center;
}

th span.running-total {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background);
    border-radius: 50%;

    opacity: 1;
}


@media only screen and (max-width: 770px) {
    .admin-login {
        margin: auto;
        margin-top: 50px;
        max-width: 90%;
        padding: 10px;
    }

    .admin-container {
        max-width: 90%;
        margin: auto;
        margin-top: 50px;
    }

    .admin-container table thead {
        display: none;
    }

    .admin-container table,
    .admin-container table tbody,
    .admin-container table tr,
    .admin-container table td {
        display: block;
    }

    .admin-container table tr {
        margin-bottom: 15px;
    }

    .admin-container table td {
        text-align: right;
        padding-left: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
    }

    .admin-container table td::before{
        content: attr(data-label) " " attr(total) ;
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }

    .admin-container table textarea {
        max-width: 50%;
    }

    .admin-container table span {
        cursor: pointer;
        background-color: var(--primary);
        padding: 2px;
        color: var(--secondary);
        margin-right: 10px;
    }

    td.action {
        flex-direction: none;
        justify-content: none;
        padding: 12px 15px !important;
    }
}