.test-container {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;
    background-color: #FF9909;

}

.test-main-content {
    margin-top: -15%;
    width: 80%;
    margin-left: auto ;
    margin-right: auto;
}

@media only screen and (max-width: 770px) {
    
}

