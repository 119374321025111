.gallery-container {
    max-width: 100%;
    overflow: hidden;
    position: relative;


}

.gallery-folder-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10%;
    color: white;
    text-decoration: underline;
    cursor: pointer;

    width: fit-content;
}

.gallery-folder {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.719);

}

.gallery-inner-folder {
    position: relative;
    max-width: 100%;
    max-height: 100%;
}

.gallery-icon {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    cursor: pointer;
}

.gallery-icon:active {
    background-color: rgb(209, 209, 209);
}

.gallery-icon.next {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateX(50%);
}

.gallery-icon.previous {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(-50%);
}

.gallery-icon.close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.gallery-folder.hidden {
    display: none;
}

.img-wrapper {
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    padding: 10px;
    min-width: 300px;
}

.img-wrapper img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    padding: 1%;
}

@media only screen and (max-width: 770px) {
    .gallery-container {
        grid-template-columns: 1fr;
    }

    .gallery-inner-folder {
        margin-top: 60px;
    }

    .gallery-icon.close {
        top: 10px;
        right: 20px;
    }

    .img-wrapper {
        max-width: 90%;
    }
}