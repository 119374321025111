.event-container {
    display: flex;
    flex-direction: column;
    font-family: var(--event-title);
    color: rgb(192,0,0);
    align-items: center;
    white-space: pre-wrap;
    margin-top: 40px;
    font-size: 1.3rem;
}

.event-container p {
    margin: 0px;
    text-align: center;
}

p.event-title {
    font-weight: 500;
    font-size: 2.2rem;
    margin-bottom: 0;
}

.event-container p a {
    color: inherit;
    text-decoration: none;
}

.event-day, .event-venue {
    font-family: var(--event-text);
}

.event-venue-title {
    font-family: var(--event-text);
    font-weight: bold;
}

@media only screen and (max-width: 770px) {
    .event-container {
        margin-top: 20px;
        font-size: 1rem;
    }

    p.event-title {
        font-size: 1.8rem;
    }
}