.em-container .selectable {
    cursor: auto;
}

.em-container h3 {
    font-family: var(--alegreya-font);
    font-style: italic;
}

.counter {
    display: flex;
    align-items: center;
    padding: 2px;
    color: var(--primary)
}

.counter svg {
    cursor: pointer;
    margin: 2px;
    padding: 3px;
    font-size: 1.3rem;
    border: 1px solid #cccccc;
}

.counter span {
    font-weight: bold;
    padding: 5px;
}

