@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cookie&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{

  --primary: #ffffff; /*#1F584F; */
  --secondary: #2d2d2d;
  --background: #FF9909;
  --card-background: #FF9909;
  --primary-font:'Alegreya Sans', sans-serif;
  --secondary-font: 'Dancing Script', cursive;
  --event-title: 'Cookie', cursive;
  --event-text:'Alegreya Sans', sans-serif;
  --form-font: 'Noto Sans JP', sans-serif;

  --dancing-font: 'Dancing Script', cursive;
  --alegreya-font: 'Alegreya Sans', sans-serif;

  --cookie-font: 'Cookie', cursive;
  --tangerine-font: 'Tangerine', cursive;
}


html,
body, #root {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: var(--background) ;
    /* font-size: calc(0.4rem + 1vw); */
}

.App {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-height: 100%;
    height: auto;
    overflow: hidden;
}


.main-wrapper {
  margin-top: -6%;
  width: 75%;
  max-width: 1200px;
  -webkit-align-self: center;
          align-self: center;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.title {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: var(--primary);
}

.title h1 {
  margin: 0;
  padding: 2%;
  font-family: var(--dancing-font);
  font-size: 3rem;
  letter-spacing: 0.4rem;
  font-weight: 500;
}

.title p {
  margin: 0;
  font-size: 1.7rem;
  font-style: italic;
  font-family: var(--alegreya-font);
}

.headImg img {
  width: 100%;
  min-height: 150px;
  object-fit: contain;
}

.footImg img {
  width: 100%;
  min-height: 150px;
  object-fit: contain;
}

p.error {
  padding: 5px;
  margin: 0;
  color: red;
}

picture.footImg {
  margin-bottom: -10px;
}

@media only screen and (max-width: 770px) {
  .main-wrapper {
    width: 100%;
    padding: 5px;
    margin: 0;
  }

  .title h1 {
    font-size: 2.5rem;
    letter-spacing: 0;
    text-align: center;
  }

  .title p {
    text-align: center;
    font-size: 0.8rem;
  }

  .headImg {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  

  
}
.nav-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 5px;
    margin-top: 5%;
    margin-bottom: 5%;
    z-index: 100;
}

.nav-container a {
    text-decoration: none;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--primary);
    font-family: var(--primary-font);
}

.nav-container a:hover {
    border-bottom: 2px solid var(--primary);
}

.nav-container a.active {
    border-bottom: 2px solid var(--primary) ;
}

.nav-icon {
    display: none;
}

.nav-container h2 {
    display: none;
}



@media only screen and (max-width: 770px) {
    .nav-container {
        position: fixed;
        top: 0;
        left: 0;
        background-color: #ffffff;
        margin: 0;
        width: 100%;
        height: 50px;

        -webkit-flex-direction: column;

                flex-direction: column;
        -webkit-justify-content:flex-start;
                justify-content:flex-start;
        -webkit-align-items: center;
                align-items: center;

        transition: all ease 0.7s;
    }

    .nav-closed {
        opacity: 0;
        -webkit-transform: scaleY(0);
                transform: scaleY(0);
        transition: all ease 0.7s;
    }

    .nav-open {
        height: 100%;
        opacity: 1;
        transition: all ease 0.7s;
    }

    .nav-icon {
        color: var(--secondary);
        display:block;
        position: absolute;
        font-size: 2.5rem;
        left: 10px;
        top: 10px;
    }

    .nav-container h2{
        display: block;
        margin: 0;
        font-family: var(--primary-font);
        color: var(--secondary);
        font-size: 2rem;
        font-weight: 500;
        position: absolute;
        padding: 5px;
        top: 10;
    }

    .nav-container a {
        color: var(--secondary);
        font-size: 1.2rem;
        padding: 10px;
    }

    .nav-container a.home-link {
        margin-top: 75px;
    }
}
.home-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.home-greeting {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: var(--tangerine-font);
    color: var(--primary);
    letter-spacing: 0.1rem;
   
}

.home-left {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
}

.home-right {
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
    border-top: 1px solid var(--primary);
}

.home-greeting h2 {
    padding: 2%;
    font-size: 2.5rem;
    font-weight: 100;
}

.home-container button {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: 10%;
    min-width: 100px;
    -webkit-align-self: center;
            align-self: center;
    background-color: var(--primary);
    color: var(--secondary);
    font-family: var(--primary-font);
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    border: none;
    border-radius: 3px;
    margin-bottom: 3%;
    font-weight: bold;
}

.home-container button:hover, .home-container button:active {
    background-color: #dadada;
}

@media only screen and (max-width: 770px) {
    .home-greeting {
        margin: 0px;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .home-greeting h2 {
        width: 100%;
        font-size: 1.7rem;
    }

    .home-right {
        border-left: none;
        border-top: 1px solid var(--primary);
    }
}
.event-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    font-family: var(--event-title);
    color: rgb(192,0,0);
    -webkit-align-items: center;
            align-items: center;
    white-space: pre-wrap;
    margin-top: 40px;
    font-size: 1.3rem;
}

.event-container p {
    margin: 0px;
    text-align: center;
}

p.event-title {
    font-weight: 500;
    font-size: 2.2rem;
    margin-bottom: 0;
}

.event-container p a {
    color: inherit;
    text-decoration: none;
}

.event-day, .event-venue {
    font-family: var(--event-text);
}

.event-venue-title {
    font-family: var(--event-text);
    font-weight: bold;
}

@media only screen and (max-width: 770px) {
    .event-container {
        margin-top: 20px;
        font-size: 1rem;
    }

    p.event-title {
        font-size: 1.8rem;
    }
}
.footer-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    color: rgb(192,0,0);
}

.footer-container h2 {
  margin-bottom: 5%;
  padding: 2%;
  font-family: var(--tangerine-font);
  font-size: 3.0rem;
  font-weight: bold;
  color: #ffffff;
  border-bottom: 2px solid #ffffff; ;
}

.footer-container p {
    font-family: var(--primary-font);
    font-size: 1.4rem;
    font-style: italic;
    margin: 5px;
}

.footer-nav {
  display: none;
}

a.footer-admin{
  font-family: var(--secondary-font);
  font-style: normal;
  font-size: small;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
}

@media only screen and (max-width: 770px) {
  .footer-nav {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 100%;
    margin-top: 10px;
  }

  .footer-nav span {
    display: -webkit-flex;
    display: flex;
    padding: 6px;
    border-top: 1px solid #ffffff;
    border-left: none;
    border-right: none;
    -webkit-justify-content: center;
            justify-content: center;
  }

  .footer-container .footer-nav {
    border-bottom: 1px solid #ffffff ;
  }

  .footer-nav a {
    font-family: var(--primary-font);
    font-size: 1.2rem;
    padding: 2px;
    color: var(--primary);
    text-decoration: none;
    border-bottom: 2px solid rgba(0,0,0,0);
  }

  .footer-nav a.active {
    border-bottom: 2px solid var(--primary);
  }


}
.travel-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.travel-header {
    color: var(--primary);
    font-family: var(--alegreya-font);
    font-style: italic;
    font-size: 1.5rem;
}

.travel-title {
    color: var(--primary);
    font-family: var(--primary-font);
    margin: 0;
    font-weight: bold;
}

.travel-subtitle {
    color: var(--primary);
    font-family: var(--primary-font);
    margin-top: 0;
}

.travel-container a {
    text-decoration: none;
    color: inherit;
}

@media only screen and (max-width: 770px)  {
    .travel-container {
        display: -webkit-flex;
        display: flex;
        padding: 2%;
        margin: 2%;
    }
}
.respond-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 50px;
}
.form-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 80%;
    padding: 10px;

    background-color: var(--card-background);

    border-radius: 3px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);

    margin-bottom: 10px;
}

.form-container label {
    font-family: var(--primary-font);
    color: var(--primary);
    padding: 5px;
    font-weight: bold;
}

.form-container input {
    padding: 10px;
    outline: none;
    border: 1px solid var(--primary);
    margin: 5px;
    border-radius: 5px;
    font-family: var(--form-font);
    font-weight: 500;
    letter-spacing: 2px;
}

.form-container button {
    padding: 10px;
    margin: 5px;
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
}

.form-container button:hover {
    background-color: #dadada;
}
.selector-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 80%;
    padding: 10%;
    background-color: var(--card-background);
    border-radius: 3px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}

.selector-container h3 {
    font-family: var(--secondary-font);
    color: var(--primary);
}

.selectable {
    width: 60%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    margin-top: 10px;
}

.selectable:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
}

.selectable p {
    margin: 0;
    color: var(--primary)
}

.selector-container .head {
    font-family: var(--primary-font);
    font-size: 1.4rem;
    font-weight: bold;
}

.selector-container .tail {
    font-family: var(--primary-font);
    font-size: 1rem;
    letter-spacing: 0.1rem;
}

.selectable .arrow {
    position: absolute;
    top: 45%;
    bottom: 0;
    right: 10px;
}

.select-retry {
    color: var(--primary);
}

.select-retry span {
    text-decoration: underline;
    cursor: pointer;
}

.selector-container button {
    padding: 10px;
    margin: 5px;
    font-family: var(--primary-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    width: 100%;
    -webkit-align-self: center;
            align-self: center;
}

.selector-container button:hover {
    background-color: #dadada;
}

@media only screen and (max-width: 770px) {
    .selector-container {
        padding: 10px;
    }

    .selectable {
        width: auto;
    }
}
.em-container .selectable {
    cursor: auto;
}

.em-container h3 {
    font-family: var(--alegreya-font);
    font-style: italic;
}

.counter {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2px;
    color: var(--primary)
}

.counter svg {
    cursor: pointer;
    margin: 2px;
    padding: 3px;
    font-size: 1.3rem;
    border: 1px solid #cccccc;
}

.counter span {
    font-weight: bold;
    padding: 5px;
}


.admin-login {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: var(--card-background);
    padding: 10%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    font-family: var(--form-font);
}

.admin-login h1 {
    color: var(--primary)
}

.admin-login label {
    color: var(--primary);
    font-weight: bold;
    padding: 5px;
}

.admin-login input {
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 2px solid #1f584f77;
    font-family: var(--form-font);
}

.admin-login input:focus {
    border: 2px solid var(--primary);
}

.admin-login button {
    padding: 10px;
    margin: 5px;
    font-family: var(--form-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    width: 100%;
    -webkit-align-self: center;
            align-self: center;
}

.admin-login button:hover {
    background-color: #dadada;
}

.admin-login p {
    color: rgb(196, 9, 9);
}

.table-wrapper {
    
    max-width: 100%;
    overflow-x: scroll;
}

table.admin-table{
    box-sizing: border-box;
    border-collapse: collapse;
    font-family: var(--form-font);
    margin-left: auto;
    margin-right: auto;
}

table.admin-table tbody tr td {
   max-width: 100%;
}



.admin-container table td, 
.admin-container table th {
    padding: 12px 15px;
    border: 1px solid #dddddd;
    text-align: center;
    font-size: 0.9rem;
}

.admin-container table th {
    background-color: var(--primary);
    color: var(--card-background);
    font-size: 0.7rem;
    position: relative;
}

.admin-container tbody tr:nth-child(even) {
    background-color: #f5f5f5;
}

.admin-container table input {
    box-sizing: border-box;
    width: 100%;
    font-family: var(--form-font);
    height: 100%;
    padding: 10px;
    outline: none;
    border: none;
    background-color: rgba(0, 0, 0, 0);
}

.admin-container table textarea {
    box-sizing: border-box;
    width: 100%;
    font-family: var(--form-font);
    height: 100%;
    padding: 5px;
    border: none;
    resize: none; 
    outline: none;
    background-color: rgba(0, 0, 0, 0);
}

td.input-box {
    padding: 0px !important;
}

.admin-container table span {
    border-radius: 2px;
    cursor: pointer;
    background-color: var(--primary);
    padding: 2px;
    color:var(--secondary);
    margin-top: 15%;
}

.admin-container table span:hover {
    background-color: #dadada;
}

td.action {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding: 0 !important;
}

.invite-button {
    padding: 12px 20px;
    margin: 3px;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    font-family: var(--form-font);
}

.admin-button-control {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

th span.running-total {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 15px;
    height: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--background);
    border-radius: 50%;

    opacity: 1;
}


@media only screen and (max-width: 770px) {
    .admin-login {
        margin: auto;
        margin-top: 50px;
        max-width: 90%;
        padding: 10px;
    }

    .admin-container {
        max-width: 90%;
        margin: auto;
        margin-top: 50px;
    }

    .admin-container table thead {
        display: none;
    }

    .admin-container table,
    .admin-container table tbody,
    .admin-container table tr,
    .admin-container table td {
        display: block;
    }

    .admin-container table tr {
        margin-bottom: 15px;
    }

    .admin-container table td {
        text-align: right;
        padding-left: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
    }

    .admin-container table td::before{
        content: attr(data-label) " " attr(total) ;
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 15px;
        font-weight: bold;
        text-align: left;
    }

    .admin-container table textarea {
        max-width: 50%;
    }

    .admin-container table span {
        cursor: pointer;
        background-color: var(--primary);
        padding: 2px;
        color: var(--secondary);
        margin-right: 10px;
    }

    td.action {
        -webkit-flex-direction: none;
                flex-direction: none;
        -webkit-justify-content: none;
                justify-content: none;
        padding: 12px 15px !important;
    }
}
.invite-container {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 200;
    background-color: rgba(0,0,0,0.6);

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;

    font-family: var(--form-font);

    transition: all ease 0.4s;
}

.hidden {
    /* display: none; */
    opacity: 0;
    z-index: -100;
    transition: all ease 0.4s;
}

.invite-form {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 5%;
    border-radius: 3px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    width: 70%;
    max-width: 500px;
    background-color: var(--card-background);

    position: relative;
}

.invite-form label {
    padding: 5px;
    font-weight: bold;
}

.invite-form input {
    font-family: var(--form-font);
    margin: 5px;
    padding: 5px;
    outline: none;
    border: 1px solid var(--primary);
}

.invite-form button {
    padding: 10px;
    margin: 5px;
    font-family: var(--form-font);
    font-weight: bold;
    font-size: 1.2rem;
    color: var(--secondary);
    background-color: var(--primary);
    border: none;
    width: 100%;
    -webkit-align-self: center;
            align-self: center;
}

.invite-form button:hover {
    background-color: #dadada;
}

.invite-form p {
    margin: 3px;
    color: rgba(228, 8, 8, 0.959);
}

p.success {
    color: rgb(17 156 0);
}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    color: var(--primary);
    cursor: pointer;
}
.test-container {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;
    background-color: #FF9909;

}

.test-main-content {
    margin-top: -15%;
    width: 80%;
    margin-left: auto ;
    margin-right: auto;
}

@media only screen and (max-width: 770px) {
    
}


.gallery-container {
    max-width: 100%;
    overflow: hidden;
    position: relative;


}

.gallery-folder-title {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 10%;
    color: white;
    text-decoration: underline;
    cursor: pointer;

    width: -webkit-fit-content;

    width: -moz-fit-content;

    width: fit-content;
}

.gallery-folder {
    position: fixed;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: rgba(0, 0, 0, 0.719);

}

.gallery-inner-folder {
    position: relative;
    max-width: 100%;
    max-height: 100%;
}

.gallery-icon {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: white;
    cursor: pointer;
}

.gallery-icon:active {
    background-color: rgb(209, 209, 209);
}

.gallery-icon.next {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
}

.gallery-icon.previous {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.gallery-icon.close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.gallery-folder.hidden {
    display: none;
}

.img-wrapper {
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    padding: 10px;
    min-width: 300px;
}

.img-wrapper img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.video-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    max-width: 100%;
    padding: 1%;
}

@media only screen and (max-width: 770px) {
    .gallery-container {
        grid-template-columns: 1fr;
    }

    .gallery-inner-folder {
        margin-top: 60px;
    }

    .gallery-icon.close {
        top: 10px;
        right: 20px;
    }

    .img-wrapper {
        max-width: 90%;
    }
}
.Lightbox_container__2aBmF {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 800px;
    max-width: 100%;
    height: 600px;
    min-height: 600px;
    background-color: rgb(31, 31, 31);
    padding: 1%;
    position: relative;
    box-sizing: border-box;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Lightbox_main__HmLfj {
    display: -webkit-flex;
    display: flex;
    height: 500px;
    background-color: rgba(255, 255, 255, 0.068);
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Lightbox_main__HmLfj img {
    width: auto;
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
    display: block;
}

.Lightbox_picker__3PdTI {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    min-height: 85px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-top: 2px;
}

.Lightbox_preview__1jLIC {
    display: -webkit-flex;
    display: flex;
    width: 75px;
    height: 75px;
    background-color: rgb(14, 14, 14);
    border-radius: 8px;
    margin: 3px;
    overflow: hidden;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid rgb(14, 14, 14);
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Lightbox_selected__3nckb {
    border-color: green;
}

.Lightbox_preview__1jLIC:hover {
    border-color: white;
}

.Lightbox_preview__1jLIC img {
    max-width: 100%;
    object-fit: contain;
}

.Lightbox_next__2I0vz {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: rgba(255, 255, 255, 0.452);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
    z-index: 1;
}

.Lightbox_previous__sVDiT {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: rgba(255, 255, 255, 0.452);
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    z-index: 1;
}

.Lightbox_next__2I0vz:hover,
.Lightbox_previous__sVDiT:hover {
    background-color: rgba(255, 255, 255, 1);
}

@media only screen and (max-width: 770px) {
    .Lightbox_container__2aBmF {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
}
